module services {
    export module master {
        export class shippingPriorityService implements interfaces.master.IShippingPriorityService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            //Gets list of all Terms Of Payment for a dropdown/autocomplete list
            getDropdownList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "ShippingPriority/GetDropdownList", {
                    entityId: '@entityId',
                    transportModeId: '@transportModeId',
                    IsInbound: '@IsInbound'
                });
            }
        }
    }
    angular.module("app").service("shippingPriorityService", services.master.shippingPriorityService);
}